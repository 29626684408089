import { createContext, useContext } from 'react'

export interface BreadCrumb {
  label: string
  weight?: maybe<number>
}

interface BreadCrumbsContextType {
  crumbs: BreadCrumb[]
}

export const BreadCrumbsContext = createContext<BreadCrumbsContextType>({
  crumbs: [],
})

export const useBreadCrumbs = () => useContext(BreadCrumbsContext)
