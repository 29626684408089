import React, { FC } from 'react'
import { Page } from 'Layout/Page'
import { Spinner } from 'UI/Spinner'
import styles from './LoadingPage.module.scss'

export const LoadingPage: FC = () => {
  return (
    <Page className={styles.loadingPage}>
      <Spinner />
    </Page>
  )
}
