import React, { FC, useContext, useState, useMemo } from 'react'
import { Storage } from 'Utils/Storage'
import { byEnvironment } from './environment'
import { byDeployEnvironment } from './deployEnvironment'

export const LOCALHOST_ENDPOINT = 'http://localhost:4000'
export const PRODUCTION = 'learn.thinkcerca.com'
export const STAGE = 'stage.thinkcerca.com'
export const DEVELOPMENT = 'dev.thinkcerca.com'
export const PRODUCTION_ENDPOINT = `https://${PRODUCTION}`
export const STAGE_ENDPOINT = `https://${STAGE}`
export const DEVELOPMENT_ENDPOINT = `https://${DEVELOPMENT}`
export const REVIEW_APP_ENDPOINT = window.location.origin

export interface EndpointContextType {
  buildEndpointUrl: (path: string) => string
  endpoint: string
  setEndpoint: (endpoint: string) => boolean
  endpointDisplayName: () => string
}

const EndpointContext = React.createContext<EndpointContextType>({
  buildEndpointUrl: () => '',
  endpoint: '',
  setEndpoint: () => false,
  endpointDisplayName: () => '',
})

export const useEndpoint = () => useContext(EndpointContext)

const isAllowedForDev = (newEndpoint: string): boolean => {
  return !newEndpoint.includes(PRODUCTION) && !newEndpoint.includes(STAGE)
}

const initialEndpoint = (): string => {
  return byDeployEnvironment({
    review: REVIEW_APP_ENDPOINT,
    dev:
      Storage.getEndpoint() ||
      byEnvironment({
        development:
          process.env.NODE_ENV === 'production' ? DEVELOPMENT_ENDPOINT : LOCALHOST_ENDPOINT,
        test: LOCALHOST_ENDPOINT,
        production: DEVELOPMENT_ENDPOINT,
      }),
    staging: STAGE_ENDPOINT,
    production: PRODUCTION_ENDPOINT,
  })
}

export const EndpointProvider: FC = ({ children }) => {
  const [endpoint, setEndpoint] = useState(initialEndpoint())
  const value = useMemo(
    () => ({
      buildEndpointUrl: (path: string) => `${endpoint}${path}`,
      endpoint,
      setEndpoint: byDeployEnvironment({
        review: () => {
          alert(
            'To change the endpoint in a review app, you much change the `REACT_APP_REVIEW_API_URL` variable in Heroku',
          )
          return false
        },
        dev: (newEndpoint: string) => {
          if (isAllowedForDev(newEndpoint)) {
            Storage.setEndpoint(newEndpoint)
            setEndpoint(newEndpoint.replace(/\/$/, ''))
            return true
          }
          return false
        },
        staging: () => false,
        production: () => false,
      }),
      endpointDisplayName: () => {
        switch (endpoint) {
          case LOCALHOST_ENDPOINT:
          case `${LOCALHOST_ENDPOINT}/`:
            return 'Localhost'
          case PRODUCTION_ENDPOINT:
          case `${PRODUCTION_ENDPOINT}/`:
            return 'Production'
          case STAGE_ENDPOINT:
          case `${STAGE_ENDPOINT}/`:
            return 'Stage'
          case DEVELOPMENT_ENDPOINT:
          case `${DEVELOPMENT_ENDPOINT}/`:
            return 'Development'
          default:
            const custom = 'Custom'
            return byDeployEnvironment({
              review: 'Review',
              dev: custom,
              staging: custom,
              production: custom,
            })
        }
      },
    }),
    [endpoint],
  )

  return <EndpointContext.Provider value={value}>{children}</EndpointContext.Provider>
}
