export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
export const ANIMATION_TRANSITION_MS = 300
export const TOKEN_HEADER_KEY = 'X-Auth-Token'

export const availableStatuses = ['Draft', 'Published', 'Archived']
export const availableUnitTypes = [
  { label: 'Longer Works', key: 'LONGER_WORKS', value: 'LONGER_WORKS' },
]
export const availableGrades = [
  {
    label: 'Grade 3',
    key: 3,
    value: 3,
  },
  {
    label: 'Grade 4',
    key: 4,
    value: 4,
  },
  {
    label: 'Grade 5',
    key: 5,
    value: 5,
  },
  {
    label: 'Grade 6',
    key: 6,
    value: 6,
  },
  {
    label: 'Grade 7',
    key: 7,
    value: 7,
  },
  {
    label: 'Grade 8',
    key: 8,
    value: 8,
  },
  {
    label: 'Grade 9',
    key: 9,
    value: 9,
  },
  {
    label: 'Grade 10',
    key: 10,
    value: 10,
  },
  {
    label: 'Grade 11',
    key: 11,
    value: 11,
  },
  {
    label: 'Grade 12',
    key: 12,
    value: 12,
  },
  {
    label: 'Higher Ed',
    key: 13,
    value: 13,
  },
]
