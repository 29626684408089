import React, { FC, createContext, useContext, useState, useMemo } from 'react'
import { Storage } from 'Utils/Storage'

interface HighlightFocusContextType {
  shouldHighlightFocus: boolean
  setShouldHighlightFocus: (value: boolean) => void
}

const HighlightFocusContext = createContext<HighlightFocusContextType>({
  shouldHighlightFocus: false,
  setShouldHighlightFocus: () => null,
})

export const useHighlightFocus = () => useContext(HighlightFocusContext)

export const HighlightFocusProvider: FC = ({ children }) => {
  const [shouldHighlightFocus, setShouldHighlightFocus] = useState(Storage.getHighlightFocus())

  const value = useMemo(() => {
    return {
      shouldHighlightFocus,
      setShouldHighlightFocus: (value: boolean) => {
        Storage.setHighlightFocus(value)
        setShouldHighlightFocus(value)
      },
    }
  }, [shouldHighlightFocus])

  return <HighlightFocusContext.Provider value={value}>{children}</HighlightFocusContext.Provider>
}
