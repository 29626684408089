import classNames from 'classnames'
import React, { FC } from 'react'
import { IconContext } from 'react-icons'
import styles from './Icons.module.scss'

export {
  MdAdd as PlusSign,
  MdCheck as Checkmark,
  MdChevronLeft as ChevronLeft,
  MdChevronRight as ChevronRight,
  MdClose as Close,
  MdDehaze as Drag,
  MdExpandLess as Collapse,
  MdExpandMore as Expand,
  MdFirstPage as FirstPage,
  MdLastPage as LastPage,
  MdSearch as Search,
} from 'react-icons/md'

export const IconProvider: FC<IconContext> = ({ children, className, ...props }) => {
  return (
    <IconContext.Provider value={{ className: classNames(styles.icon, className), ...props }}>
      {children}
    </IconContext.Provider>
  )
}
