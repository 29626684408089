import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { useHighlightFocus } from 'Config/useHighlightFocus'
import styles from './AppContainer.module.scss'

export const AppContainer: FC = ({ children }) => {
  const { shouldHighlightFocus } = useHighlightFocus()
  return (
    <div className={styles.appContainer}>
      <Helmet>
        <body className={classNames({ shouldHighlightFocus })} />
      </Helmet>
      {children}
    </div>
  )
}
