import React, { FC } from 'react'
import { Page } from 'Layout/Page'

export const HomePage: FC = () => {
  return (
    <Page pageTitle='Home'>
      <h1>Welcome</h1>
    </Page>
  )
}
