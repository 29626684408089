const REVIEW = 'review'
const DEV = 'development'
const STAGING = 'staging'
const PRODUCTION = 'production'

const DEPLOY_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV || DEV

export const byDeployEnvironment = <T>({
  review,
  dev,
  staging,
  production,
}: {
  review: T
  dev: T
  staging: T
  production: T
}): T => {
  switch (DEPLOY_ENVIRONMENT) {
    case REVIEW:
      return review
    case DEV:
      return dev
    case STAGING:
      return staging
    case PRODUCTION:
      return production
    default:
      return dev
  }
}
