import React, { FC } from 'react'
import { BreadCrumb, useBreadCrumbs } from './useBreadCrumbs'
import { NavLink } from 'react-router-dom'
import flatMap from 'lodash.flatmap'
import styles from './BreadCrumbs.module.scss'

export { BreadCrumbsProvider } from './BreadCrumbsProvider'

const arrayOf = <T extends any>(length: number, value: T): T[] => {
  const array = new Array(length)
  array.fill(value)
  return array
}

export const pathForCrumb = ({
  allCrumbs,
  index,
}: {
  allCrumbs: BreadCrumb[]
  index: number
}): string => {
  if (index + 1 === allCrumbs.length) {
    return '.'
  }

  const crumbs = [...allCrumbs].reverse().slice(0, allCrumbs.length - index - 1)
  return flatMap(crumbs, ({ weight }) => arrayOf(weight || 1, '..')).join('/')
}

export const BreadCrumbs: FC<{ crumb?: maybe<BreadCrumb> }> = ({ crumb }) => {
  const { crumbs } = useBreadCrumbs()
  const allCrumbs = crumb ? [...crumbs, crumb] : crumbs

  return (
    <nav aria-label='Breadcrumb'>
      <ol className={styles.breadCrumbs}>
        {allCrumbs.map((crumb, index) => (
          <li key={index} className={styles.breadCrumb}>
            <span className={styles.slash} aria-hidden>
              /&nbsp;
            </span>
            <NavLink to={pathForCrumb({ allCrumbs, index })}>{crumb.label}</NavLink>
            &nbsp;
          </li>
        ))}
      </ol>
    </nav>
  )
}
