import { Component, ReactNode } from 'react'

interface Props {
  onDidCatch?: (error: Error, errorInfo: { componentStack: string }) => void
  renderError: (options: { error: Error; retry: () => void }) => ReactNode
}

interface State {
  error: maybe<Error>
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    const { onDidCatch } = this.props
    onDidCatch && onDidCatch(error, errorInfo)
  }

  render() {
    const { children, renderError } = this.props
    const { error } = this.state

    if (error) {
      return renderError({ error, retry: () => this.setState({ error: null }) })
    }

    return children
  }
}
