import classNames from 'classnames'
import React, { FC, forwardRef, HTMLProps } from 'react'
import styles from './Button.module.scss'

type ButtonProps = HTMLProps<HTMLButtonElement>

type BaseProps = Omit<ButtonProps, 'type'>

interface Props extends BaseProps {
  danger?: boolean
  type?: 'button' | 'reset' | 'submit'
}

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { className, type, danger, ...props },
  ref,
) {
  return (
    <button
      ref={ref}
      className={classNames(styles.button, className, { [styles.danger]: danger })}
      type={type || 'button'}
      {...props}
    />
  )
})

export const PlainButton: FC<Props> = ({ className, type, danger, ...props }) => (
  <button
    className={classNames(styles.plainButton, className, { [styles.danger]: danger })}
    type={type || 'button'}
    {...props}
  />
)
