import React, { FC } from 'react'
import { DEFAULT_ERROR_MESSAGE } from 'Constants'
import { Header, Title } from 'UI/Header'
import { AppContainer } from 'Layout/AppContainer'
import { Page } from 'Layout/Page'
import { Button } from 'UI/Button'
import { ErrorBoundary } from './ErrorBoundary'
import styles from './AppErrorBoundary.module.scss'

export const AppErrorBoundary: FC = ({ children }) => {
  return (
    <ErrorBoundary
      renderError={({ error, retry }) => (
        <AppContainer>
          <Page pageTitle='Error' className={styles.appErrorBoundary}>
            <Header>
              <Title>{DEFAULT_ERROR_MESSAGE}</Title>
            </Header>
            <p>{error.message}</p>
            <div className={styles.buttonContainer}>
              <Button onClick={retry}>Retry</Button>
            </div>
          </Page>
        </AppContainer>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
