import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './Header.module.scss'

interface HeaderProps {
  className?: string
}

export const Header: FC<HeaderProps> = ({ className, ...props }) => {
  return <header className={classNames(styles.header, className)} {...props} />
}

interface TitleProps {
  className?: string
}

export const Title: FC<TitleProps> = ({ className, children, ...props }) => {
  return (
    <h1 className={classNames(styles.title, className)} {...props}>
      {children}
    </h1>
  )
}

export const HeaderActions: FC = ({ children }) => {
  return <div className={styles.headerActions}>{children}</div>
}
