import React, {
  FC,
  createContext,
  useState,
  useMemo,
  useContext,
  useDebugValue,
  useCallback,
} from 'react'
import remove from 'lodash.remove'
import uniqueId from 'lodash.uniqueid'

export type FlashMessageType = 'success' | 'error' | 'notice'

export interface FlashMessageInterface {
  id: string
  message: string
  type: FlashMessageType
}

interface FlashMessagesContextType {
  flashMessages: FlashMessageInterface[]
  addFlashMessage: (flashMessage: FlashMessageInterface) => void
  removeFlashMessage: (flashMessageId: string) => void
}

const FlashMessagesContext = createContext<FlashMessagesContextType>({
  flashMessages: [],
  addFlashMessage: () => null,
  removeFlashMessage: () => null,
})

export const FlashMessagesProvider: FC = ({ children }) => {
  const [flashMessages, setFlashMessages] = useState<FlashMessageInterface[]>([])

  const removeFlashMessage = useCallback(
    (flashMessageId: string) => {
      setFlashMessages(prevFlashMessages =>
        remove(prevFlashMessages, ({ id }) => id !== flashMessageId),
      )
    },
    [setFlashMessages],
  )

  const addFlashMessage = useCallback(
    (flashMessage: FlashMessageInterface) =>
      setFlashMessages(prevFlashMessages => [...prevFlashMessages, flashMessage]),
    [setFlashMessages],
  )

  const value: FlashMessagesContextType = useMemo(() => {
    return {
      flashMessages,
      addFlashMessage,
      removeFlashMessage,
    }
  }, [flashMessages, addFlashMessage, removeFlashMessage])

  return <FlashMessagesContext.Provider value={value}>{children}</FlashMessagesContext.Provider>
}

const flashMessageId = () => uniqueId('flashMessage_')

const buildFlashMessage = (message: string, type: FlashMessageType): FlashMessageInterface => {
  return {
    id: flashMessageId(),
    message,
    type,
  }
}

export const useFlashMessages = () => {
  const { flashMessages, addFlashMessage, removeFlashMessage } = useContext(FlashMessagesContext)

  useDebugValue(`Total: ${flashMessages.length}`)

  return {
    flashMessages,
    removeFlashMessage,
    addSuccessFlashMessage: useCallback(
      (message: string) => addFlashMessage(buildFlashMessage(message, 'success')),
      [addFlashMessage],
    ),
    addErrorFlashMessage: useCallback(
      (message: string) => addFlashMessage(buildFlashMessage(message, 'error')),
      [addFlashMessage],
    ),
    addNoticeFlashMessage: useCallback(
      (message: string) => addFlashMessage(buildFlashMessage(message, 'notice')),
      [addFlashMessage],
    ),
  }
}
