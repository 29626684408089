import classNames from 'classnames'
import React, { FC, StrictMode } from 'react'
import { Helmet } from 'react-helmet'
import { SkipNavContent } from '@reach/skip-nav'
import styles from './Page.module.scss'
import { BreadCrumbs } from 'UI/BreadCrumbs'

interface Props {
  breadCrumbWeight?: number
  breadCrumbRoot?: boolean
  className?: string
  pageTitle?: string
}

export const Page: FC<Props> = ({
  children,
  className,
  pageTitle,
  breadCrumbWeight,
  breadCrumbRoot,
}) => {
  return (
    <>
      <Helmet>
        <title>ThinkCERCA | {pageTitle || 'Content Garden'}</title>
      </Helmet>
      <StrictMode>
        <SkipNavContent />
        <main role='main' className={classNames(styles.page, className)}>
          {pageTitle && (
            <div className={styles.breadCrumbsContainer}>
              <BreadCrumbs
                crumb={breadCrumbRoot ? null : { label: pageTitle, weight: breadCrumbWeight }}
              />
            </div>
          )}
          {children}
        </main>
      </StrictMode>
    </>
  )
}
