import React, { FC, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from 'graphql.macro'
import { useAuth } from 'Auth/useAuth'
import { CurrentUserEmailQuery } from './GraphQLTypes/CurrentUserEmailQuery'
import styles from './CurrentUserEmail.module.scss'

const CurrentUserEmailGql = gql`
  query CurrentUserEmailQuery {
    currentUser {
      email
    }
  }
`

export const CurrentUserEmail: FC = () => {
  const { isSignedIn } = useAuth()
  const [getCurrentUser, { called, data }] = useLazyQuery<CurrentUserEmailQuery>(
    CurrentUserEmailGql,
  )

  useEffect(() => {
    isSignedIn && getCurrentUser()
  }, [isSignedIn, getCurrentUser])

  if (isSignedIn && called && data?.currentUser?.email) {
    return <div className={styles.currentUserEmail}>{data.currentUser.email}</div>
  }
  return null
}
