import classNames from 'classnames'
import React, { FC } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import styles from './Spinner.module.scss'

export const Spinner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames(styles.spinner, className)}>
      <VisuallyHidden>Loading...</VisuallyHidden>
    </div>
  )
}
