import React from 'react'
import AlertBase from '@reach/alert'

// TODO: Remove this ReachAlert file in favor of just importing directly from @reach/alert
// This is to work around an issue in the type definitions in the current package, so
// once we upgrade we should be able to remove this.
type AlertProps = {
  type?: 'assertive' | 'polite'
} & React.HTMLProps<HTMLDivElement>

export const ReachAlert: React.FunctionComponent<AlertProps> = AlertBase as any
