import React, { FC, StrictMode, Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AuthProvider } from 'Auth/useAuth'
import { GraphQLDataProvider } from 'Config/GraphQLDataProvider'
import { EndpointProvider } from 'Config/useEndpoint'
import { AppErrorBoundary } from 'ErrorHandling/AppErrorBoundary'
import { PageErrorBoundary } from 'ErrorHandling/PageErrorBoundary'
import { AppContainer } from 'Layout/AppContainer'
import { CurrentUserEmail } from 'Layout/CurrentUserEmail'
import { FlashMessagesProvider, FlashMessages } from 'Layout/FlashMessages'
import { Navigation } from 'Layout/Navigation'
import { HomePage } from 'Pages/HomePage'
import { IconProvider } from 'UI/Icons'
import { ConfirmationProvider } from 'Layout/Confirmation'
import { HighlightFocusProvider } from 'Config/useHighlightFocus'
import { LoadingPage } from 'Pages/LoadingPage'

const SignIn = lazy(() => import('Auth/SignIn' /* webpackChunkName: "SignIn" */))
const CoreUnitsRouter = lazy(
  () => import('Pages/CoreUnits/CoreUnitsRouter' /* webpackChunkName: "CoreUnitsRouter" */),
)
const CurriculumsRouter = lazy(
  () => import('Pages/Curriculums/CurriculumsRouter' /* webpackChunkName: "CurriculumsRouter" */),
)
const UnitsRouter = lazy(
  () => import('Pages/Units/UnitsRouter' /* webpackChunkName: "UnitsRouter" */),
)
const UnitsLibraryRouter = lazy(
  () => import('Pages/Units/UnitsLibraryRouter' /* webpackChunkName: "UnitsLibraryRouter" */),
)
const CoursesRouter = lazy(
  () => import('Pages/Courses/CoursesRouter' /* webpackChunkName: "CoursesRouter" */),
)
const LessonsRouter = lazy(
  () => import('Pages/Lessons/LessonsRouter' /* webpackChunkName: "LessonsRouter" */),
)
const ListSubjectsPage = lazy(
  () => import('Pages/Subjects/ListSubjectsPage' /* webpackChunkName: "ListSubjectsPage" */),
)
const SettingsRouter = lazy(
  () => import('Pages/Settings/SettingsRouter' /* webpackChunkName: "SettingsRouter" */),
)
const FlexibleLessonsRouter = lazy(
  () =>
    import(
      'Pages/FlexibleLessons/FlexibleLessonsRouter' /* webpackChunkName: "FlexibleLessonsRouter" */
    ),
)
const FlexibleLessonGroupsRouter = lazy(
  () =>
    import(
      'Pages/FlexibleLessonGroups/FlexibleLessonGroupsRouter' /* webpackChunkName: "FlexibleLessonGroupsRouter" */
    ),
)
const StandardsRouter = lazy(
  () => import('Pages/Standards/StandardsRouter' /* webpackChunkName: "StandardsRouter" */),
)

const OpenAIGenericPromptRouter = lazy(
  () =>
    import('Pages/OpenAIGenericPrompt/OpenAIGenericPromptRouter' /* webpackChunkName: "OpenAIGenericPromptRouter" */),
)

const environment = process.env.REACT_APP_DEPLOY_ENV
const productionOrStaging = environment === 'production' || environment === 'staging'

export const App: FC = () => {
  return (
    <AppErrorBoundary>
      <HighlightFocusProvider>
        <ConfirmationProvider>
          <IconProvider>
            <BrowserRouter>
              <EndpointProvider>
                <AuthProvider>
                  <GraphQLDataProvider>
                    <FlashMessagesProvider>
                      <AppContainer>
                        <StrictMode>
                          <Navigation />
                          <CurrentUserEmail />
                          <FlashMessages />
                        </StrictMode>
                        <PageErrorBoundary>
                          <Suspense fallback={<LoadingPage />}>
                            <Routes>
                              <Route path='/' element={<HomePage />} />
                              <Route path='sign-in' element={<SignIn />} />
                              <Route path='settings/*' element={<SettingsRouter />} />
                              <Route path='core-units/*' element={<CoreUnitsRouter />} />
                              <Route path='curriculums/*' element={<CurriculumsRouter />} />
                              <Route path='units/*' element={<UnitsRouter />} />
                              <Route path='library-units/*' element={<UnitsLibraryRouter />} />
                              <Route path='courses/*' element={<CoursesRouter />} />
                              <Route path='standards/*' element={<StandardsRouter />} />
                              <Route path='open-ai-generic-prompt/*' element={<OpenAIGenericPromptRouter />} />
                              {!productionOrStaging && (
                                <>
                                  <Route
                                    path='flexible-lessons/*'
                                    element={<FlexibleLessonsRouter />}
                                  />
                                  <Route
                                    path='flexible-lesson-groups/*'
                                    element={<FlexibleLessonGroupsRouter />}
                                  />
                                  <Route path='lessons/*' element={<LessonsRouter />} />
                                  <Route path='subjects/*' element={<ListSubjectsPage />} />
                                </>
                              )}
                              <Route path='*' element={<Navigate to='/sign-in' replace />} />
                            </Routes>
                          </Suspense>
                        </PageErrorBoundary>
                      </AppContainer>
                    </FlashMessagesProvider>
                  </GraphQLDataProvider>
                </AuthProvider>
              </EndpointProvider>
            </BrowserRouter>
          </IconProvider>
        </ConfirmationProvider>
      </HighlightFocusProvider>
    </AppErrorBoundary>
  )
}
