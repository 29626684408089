import React, { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloClientOptions,
  ServerError,
  ServerParseError,
  from, // is not a typo
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { useAuth } from 'Auth/useAuth'
import { useEndpoint } from './useEndpoint'
import { TOKEN_HEADER_KEY } from 'Constants'

const merge = (existing: maybe<any[]>, incoming: maybe<any[]>): any[] => {
  return incoming || existing || []
}

const buildPolicy = (...fieldNames: string[]) => {
  return {
    fields: fieldNames.reduce((acc, fieldName) => {
      return { ...acc, [fieldName]: { merge } }
    }, {}),
  }
}

const buildCache = (): InMemoryCache => {
  return new InMemoryCache({
    possibleTypes: {
      AdditionalInfoable: [
        'FlexibleLessonPage',
        'TextResponseField',
        'LinkedResponseField',
        'ShortTextResponseField',
        'SupportingShortTextResponseFieldGroup',
      ],
      FlexibleLessonPageable: [
        'FlexibleLesson',
        'FlexibleLessonPageGroup',
        'FlexibleLessonSection',
      ],
      FlexibleLessonPageGroupable: ['FlexibleLesson', 'FlexibleLessonSection'],
      FlexibleLessonOutlineable: ['FlexibleLessonPage', 'FlexibleLessonSection'],
      FlexibleLessonSectionOutlineable: ['FlexibleLessonPage', 'FlexibleLessonPageGroup'],
      FlexibleLessonStandardable: ['FlexibleLesson', 'MultipleChoiceQuestion'],
      FlexibleLessonPageOutlineable: [
        'PageSourceMaterial',
        'WritingPrompt',
        'VocabularyList',
        'LinkedResponseField',
        'TextResponseField',
        'MultipleChoiceResponseField',
        'SourceMaterialAnnotation',
        'ShortTextResponseField',
        'TopicOverview',
      ],
      SentenceStarterable: ['AdditionalInfo', 'StepModule'],
      LinkedResponseFieldable: ['TextResponseField'],
    },
    typePolicies: {
      AdditionalInfo: buildPolicy('sentenceStarters'),
      CoreUnit: buildPolicy('additionalLessons', 'lessonModules'),
      FlexibleLesson: buildPolicy('outline', 'standards', 'pages'),
      FlexibleLessonGroup: buildPolicy('difficultyGroups'),
      FlexibleLessonPage: buildPolicy('additionalInfos', 'outline', 'writingPrompts'),
      FlexibleLessonPageGroup: buildPolicy('pages'),
      FlexibleLessonSection: buildPolicy('pages', 'outline'),
      TextResponseField: buildPolicy('additionalInfos'),
      MultipleChoiceResponseField: buildPolicy('questions'),
      MultipleChoiceQuestion: buildPolicy('alternatives'),
      StepModule: buildPolicy('sentenceStarters'),
    },
  })
}

export const GraphQLDataProvider: FC = ({ children }) => {
  const { authToken, signOut } = useAuth()
  const { buildEndpointUrl } = useEndpoint()
  const navigate = useNavigate()

  const client = useMemo(() => {
    const config: ApolloClientOptions<any> = {
      cache: buildCache(),
      link: from([
        onError(({ networkError: error }) => {
          if ((error as any)?.response) {
            const serverError: ServerError | ServerParseError = error as any
            if (
              serverError.response.redirected &&
              serverError.response.url.includes('/users/sign_in')
            ) {
              signOut()
              navigate('/sign-in', { replace: true })
            }
          } else {
            console.error(error)
          }
        }),
        createUploadLink({
          headers: authToken ? { [TOKEN_HEADER_KEY]: authToken } : {},
          uri: buildEndpointUrl('/graphql'),
        }) as any,
      ]),
    }

    return new ApolloClient(config)
  }, [buildEndpointUrl, authToken, navigate, signOut])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
