export const environment = process.env.REACT_APP_DEPLOY_ENV

export const byEnvironment = <T>({
  development,
  test,
  production,
}: {
  development: T
  test: T
  production: T
}): T => {
  switch (environment) {
    case 'development':
      return development
    case 'test':
      return test
    case 'production':
      return production
    default:
      return development
  }
}
