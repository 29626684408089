const setItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value))

const getItem = (key: string): any => JSON.parse(localStorage.getItem(key) || 'null')

const removeItem = (key: string) => localStorage.removeItem(key)

const AUTH_TOKEN_KEY = 'authToken'
const AUTH_TOKEN_EXPIRES_AT_KEY = 'authTokenExpiresAt'
const AUTH_TOKEN_REFRESH_AFTER = 'authTokenRefreshAfter'
const ENDPOINT_KEY = 'endpoint'
const HIGHLIGHT_FOCUS_KEY = 'highlight-focus'

export const Storage = {
  getAuthToken: (): maybe<string> => getItem(AUTH_TOKEN_KEY),
  setAuthToken: (token: string) => setItem(AUTH_TOKEN_KEY, token),
  removeAuthToken: () => removeItem(AUTH_TOKEN_KEY),

  getAuthTokenExpiresAt: (): maybe<string> => getItem(AUTH_TOKEN_EXPIRES_AT_KEY),
  setAuthTokenExpiresAt: (tokenExpiresAt: string) =>
    setItem(AUTH_TOKEN_EXPIRES_AT_KEY, tokenExpiresAt),
  removeAuthTokenExpiresAt: () => removeItem(AUTH_TOKEN_EXPIRES_AT_KEY),

  getAuthTokenRefreshAfter: (): maybe<string> => getItem(AUTH_TOKEN_REFRESH_AFTER),
  setAuthTokenRefreshAfter: (tokenRefreshAfter: string) =>
    setItem(AUTH_TOKEN_REFRESH_AFTER, tokenRefreshAfter),
  removeAuthTokenRefreshAfter: () => removeItem(AUTH_TOKEN_REFRESH_AFTER),

  getEndpoint: (): maybe<string> => getItem(ENDPOINT_KEY),
  setEndpoint: (endpoint: string) => setItem(ENDPOINT_KEY, endpoint),
  removeEndpoint: () => removeItem(ENDPOINT_KEY),

  getHighlightFocus: (): boolean => Boolean(getItem(HIGHLIGHT_FOCUS_KEY)),
  setHighlightFocus: (value: boolean) => setItem(HIGHLIGHT_FOCUS_KEY, value),
  removeHighlightFocus: () => removeItem(HIGHLIGHT_FOCUS_KEY),
}
