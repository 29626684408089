import React, { FC } from 'react'
import { Page } from 'Layout/Page'
import { Button } from 'UI/Button'
import { DEFAULT_ERROR_MESSAGE } from 'Constants'
import { Header, Title } from 'UI/Header'
import { ErrorBoundary } from './ErrorBoundary'
import styles from './PageErrorBoundary.module.scss'

export const PageErrorBoundary: FC = ({ children }) => {
  return (
    <ErrorBoundary
      renderError={({ error, retry }) => (
        <Page pageTitle='Error' className={styles.pageErrorBoundary}>
          <Header>
            <Title>{DEFAULT_ERROR_MESSAGE}</Title>
          </Header>
          <p>{error.message}</p>
          <div className={styles.buttonContainer}>
            <Button onClick={retry}>Retry</Button>
          </div>
        </Page>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
