import React, { FC, useContext, useMemo, useState, useCallback } from 'react'
import { Storage } from 'Utils/Storage'

import { useEndpoint } from 'Config/useEndpoint'
import { refreshAuthToken } from './requestAuthToken'

export interface AuthContextType {
  authToken: maybe<string>
  authTokenExpiresAt: maybe<string>
  isSignedIn: boolean
  signIn: (authToken: string, authTokenExpiresAt: string) => void
  signOut: () => void
}

const AuthContext = React.createContext<AuthContextType>({
  authToken: null,
  authTokenExpiresAt: null,
  isSignedIn: false,
  signIn: (_authToken: string) => null,
  signOut: () => null,
})

const calculateRefreshAfterDate = (
  tokenExpiresAt: string,
): { refreshAfterDate: Date; refreshIn: number } => {
  const today = new Date()
  const expireDate = new Date(tokenExpiresAt)
  const timeBeforeTimeout = (expireDate.getTime() - today.getTime()) / 4
  const refreshAfterDate = new Date(expireDate.getTime() - timeBeforeTimeout)

  return {
    refreshAfterDate: refreshAfterDate,
    refreshIn: refreshAfterDate.getTime() - today.getTime(),
  }
}

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: FC = ({ children }) => {
  const [authToken, setAuthToken] = useState(Storage.getAuthToken())
  const { endpoint } = useEndpoint()
  const today = new Date()

  const removeSession = () => {
    Storage.removeAuthToken()
    Storage.removeAuthTokenExpiresAt()
    Storage.removeAuthTokenRefreshAfter()
    setAuthToken(null)
  }

  const refreshToken = useCallback(async () => {
    const { token, tokenExpiresAt } = await refreshAuthToken({ from: endpoint })

    if (token && tokenExpiresAt) {
      Storage.setAuthTokenExpiresAt(tokenExpiresAt)
      const { refreshAfterDate, refreshIn } = calculateRefreshAfterDate(tokenExpiresAt)
      Storage.setAuthTokenRefreshAfter(refreshAfterDate.toISOString())
      setTimeout(() => refreshToken(), refreshIn)
    } else {
      removeSession()
    }
  }, [endpoint])

  const value = useMemo(
    () => ({
      authToken,
      authTokenExpiresAt: Storage.getAuthTokenExpiresAt(),
      isSignedIn: !!authToken,
      signIn: (newAuthToken: string, newAuthTokenExpiresAt: string) => {
        Storage.setAuthToken(newAuthToken)
        Storage.setAuthTokenExpiresAt(newAuthTokenExpiresAt)
        setAuthToken(newAuthToken)
        const { refreshAfterDate, refreshIn } = calculateRefreshAfterDate(newAuthTokenExpiresAt)
        Storage.setAuthTokenRefreshAfter(refreshAfterDate.toISOString())
        setTimeout(() => refreshToken(), refreshIn)
      },
      signOut: () => {
        removeSession()
      },
    }),
    [authToken, refreshToken],
  )

  if (value.isSignedIn) {
    const expireDateString = String(Storage.getAuthTokenExpiresAt())
    const expireDate = new Date(expireDateString)

    if (expireDate < today) {
      removeSession()
    } else {
      if (Storage.getAuthTokenRefreshAfter()) {
        const refreshAfterDate = new Date(String(Storage.getAuthTokenRefreshAfter()))

        if (refreshAfterDate < today) {
          refreshToken()
        } else {
          const refreshIn = refreshAfterDate.getTime() - today.getTime()
          setTimeout(() => refreshToken(), refreshIn)
        }
      }
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
