import classNames from 'classnames'
import React, { FC, useState, useEffect, useCallback } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { ReachAlert } from 'UI/ReachAlert'
import { ANIMATION_TRANSITION_MS } from 'Constants'
import { Close } from 'UI/Icons'
import { PlainButton } from 'UI/Button'
import { FlashMessageInterface, useFlashMessages } from './FlashMessagesProvider'
import styles from './FlashMessages.module.scss'

export const FlashMessage: FC<{
  flashMessage: FlashMessageInterface
  onClose: (flashMessageId: string) => void
  contracting: boolean
}> = ({ flashMessage, onClose, contracting }) => {
  const { removeFlashMessage } = useFlashMessages()

  useEffect(() => {
    if (contracting) {
      const result = setTimeout(() => removeFlashMessage(flashMessage.id), ANIMATION_TRANSITION_MS)
      return () => clearTimeout(result)
    } else {
      const result = setTimeout(() => onClose(flashMessage.id), 5000)
      return () => clearTimeout(result)
    }
  }, [contracting, flashMessage.id, removeFlashMessage, onClose])

  return (
    <ReachAlert
      type='assertive'
      className={classNames(styles.flashMessage, styles[flashMessage.type], {
        [styles.contract]: contracting,
      })}
    >
      {flashMessage.message}
      <PlainButton className={styles.closeButton} onClick={() => onClose(flashMessage.id)}>
        <VisuallyHidden>Close</VisuallyHidden>
        <Close
          className={classNames(styles.closeIcon, { [styles.closeIconContracting]: contracting })}
        />
      </PlainButton>
    </ReachAlert>
  )
}

export const FlashMessages: FC = () => {
  const { flashMessages } = useFlashMessages()

  const [flashMessageIdsToRemove, setFlashMessageIdsToRemove] = useState<string[]>([])

  const closeFlashMessage = useCallback(
    (flashMessageId: string) => {
      setFlashMessageIdsToRemove(prevFlashMessageIdsToRemove => [
        ...prevFlashMessageIdsToRemove,
        flashMessageId,
      ])
    },
    [setFlashMessageIdsToRemove],
  )

  return (
    <div className={styles.flashMessages}>
      {flashMessages.map(flashMessage => (
        <FlashMessage
          key={flashMessage.id}
          flashMessage={flashMessage}
          contracting={flashMessageIdsToRemove.includes(flashMessage.id)}
          onClose={closeFlashMessage}
        />
      ))}
    </div>
  )
}
