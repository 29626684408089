import React, { FC, useMemo } from 'react'
import { useBreadCrumbs, BreadCrumbsContext, BreadCrumb } from './useBreadCrumbs'

export const BreadCrumbsProvider: FC<BreadCrumb> = ({ children, label, weight }) => {
  const { crumbs } = useBreadCrumbs()

  const value = useMemo(() => {
    return { crumbs: [...crumbs, { label, weight }] }
  }, [crumbs, label, weight])

  return <BreadCrumbsContext.Provider value={value}>{children}</BreadCrumbsContext.Provider>
}
