import { DEFAULT_ERROR_MESSAGE, TOKEN_HEADER_KEY } from 'Constants'
import { guard } from 'Utils/guard'
import { Storage } from 'Utils/Storage'

interface RequestAuthTokenResponse {
  token?: string
  tokenExpiresAt?: string
  error?: string
}

export const requestAuthToken = async ({
  from: endpoint,
  ...options
}: {
  email: string
  password: string
  from: string
}): Promise<RequestAuthTokenResponse> => {
  const result = await guard(async () => {
    const response = await fetch(`${endpoint}/tokens/sign_in`, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: { 'Content-Type': 'application/json' },
    })
    const responseJSON = await response.json()
    return responseJSON as RequestAuthTokenResponse
  })

  return result || { error: DEFAULT_ERROR_MESSAGE }
}

export const refreshAuthToken = async ({
  from: endpoint,
}: {
  from: string
}): Promise<RequestAuthTokenResponse> => {
  const result = await guard(async () => {
    const response = await fetch(`${endpoint}/tokens/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [TOKEN_HEADER_KEY]: Storage.getAuthToken() || '',
      },
    })
    const responseJSON = await response.json()
    return responseJSON as RequestAuthTokenResponse
  })

  return result || { error: DEFAULT_ERROR_MESSAGE }
}
