import classNames from 'classnames'
import React, {
  createContext,
  FC,
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react'
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from '@reach/alert-dialog'
import uniqueId from 'lodash.uniqueid'
import { ANIMATION_TRANSITION_MS } from 'Constants'
import { Button } from 'UI/Button'
import '@reach/dialog/styles.css'
import styles from './Confirmation.module.scss'

interface ConfirmArguments {
  onCancel?: () => void
  onConfirm: () => void
  description: string
  label: string
}

type ConfirmContextType = (options: ConfirmArguments) => void

const ConfirmContext = createContext<ConfirmContextType>(() => null)

export const useConfirm = () => useContext(ConfirmContext)

export const useConfirmAsync = (): ((options: {
  label: string
  description: string
}) => Promise<boolean>) => {
  const confirm = useConfirm()

  return (options: { label: string; description: string }) => {
    return new Promise(resolve => {
      confirm({
        ...options,
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      })
    })
  }
}

interface ConfirmationProviderState extends ConfirmArguments {
  showAlert: boolean
  id: string
}

interface ConfirmationAlertProps extends ConfirmationProviderState {
  completeConfirmation: () => void
}

export const ConfirmationAlert: FC<ConfirmationAlertProps> = ({
  completeConfirmation,
  onCancel,
  onConfirm,
  description,
  label,
  showAlert,
}) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>()
  const [hidingAlert, setHidingAlert] = useState(false)

  useEffect(() => {
    if (hidingAlert) {
      const timeoutId = setTimeout(() => {
        completeConfirmation()
        setHidingAlert(false)
      }, ANIMATION_TRANSITION_MS)

      return () => clearTimeout(timeoutId)
    }
  }, [hidingAlert, completeConfirmation])

  if (showAlert || hidingAlert) {
    return (
      <AlertDialogOverlay
        className={classNames(styles.confirmationOverlay, {
          [styles.hideConfirmationOverlay]: hidingAlert,
        })}
        leastDestructiveRef={leastDestructiveRef as any}
      >
        <AlertDialogContent className={styles.confirmationContent}>
          <AlertDialogLabel className={styles.confirmationLabel}>{label}</AlertDialogLabel>
          <AlertDialogDescription className={styles.confirmationDescription}>
            {description}
          </AlertDialogDescription>
          <div className={styles.confirmationActions}>
            <Button
              ref={leastDestructiveRef as any}
              onClick={() => {
                onCancel && onCancel()
                setHidingAlert(true)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onConfirm()
                setHidingAlert(true)
              }}
            >
              Confirm
            </Button>
          </div>
        </AlertDialogContent>
      </AlertDialogOverlay>
    )
  }
  return null
}

const generateId = () => uniqueId('confirmation-')

const defaultConfirmationProviderState = (): ConfirmationProviderState => {
  return {
    onCancel: () => null,
    onConfirm: () => null,
    description: '',
    label: '',
    showAlert: false,
    id: generateId(),
  }
}

export const ConfirmationProvider: FC = ({ children }) => {
  const [state, setState] = useState<ConfirmationProviderState>(defaultConfirmationProviderState())

  const clearState = useCallback(() => setState(defaultConfirmationProviderState()), [setState])

  const confirm: ConfirmContextType = useCallback(
    confirmOptions => {
      setState({ ...confirmOptions, id: generateId(), showAlert: true })
    },
    [setState],
  )

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}
      <ConfirmationAlert key={state.id} {...state} completeConfirmation={clearState} />
    </ConfirmContext.Provider>
  )
}
